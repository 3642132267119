export function acceptCookies() {
  $(document).on('click', '.js-cookie-accept', function () {
    let now = new Date();
    now.setFullYear(now.getFullYear() + 10);
    document.cookie = 'cookies=accepted; expires=' + now.toUTCString() + '; path=/';
    $('.custom-cookie-notice').fadeOut();
  });
}

export function isMobile() {
  return window.innerWidth < 1025;
}

export function setHeadingBgHeight() {
  const bg = $('.our-solutions--heading-bg');
  const heading = $('.our-solutions--heading-wrapper');

  if (!bg.length) return;

  bg.height(heading.outerHeight());
}

export function commonSectionMoreToggle() {
  const target = $('.section-common-1--more');

  if (!target.length) return;

  target.on('click', function () {
    const trigger = $(this);
    const content = $(this).parents('section').first().find('.section-common-1--more-content');

    if (trigger.text() === trigger.attr('data-text')) {
      trigger.text(trigger.attr('data-text-alt'));
      content.show();
    } else {
      trigger.text(trigger.attr('data-text'));
      content.hide();
    }
  })
}

export function possibilitiesAccordion() {
  const target = $('.possibilities--list-item');

  if (!target.length) return;

  target.on('click', function () {
    const el = $(this);
    const activeClass = 'possibilities--list-item__active';

    if (el.hasClass(activeClass)) {
      el.removeClass(activeClass).find('p').slideUp(250);
    } else {
      el.siblings().removeClass(activeClass).find('p').slideUp(250);
      el.addClass(activeClass).find('p').slideDown(250);
    }
  })
}

export function faqAccordion() {
  const target = $('.faq--list-item');

  if (!target.length) return;

  target.on('click', function () {
    const el = $(this);
    const activeClass = 'faq--list-item__active';

    if (el.hasClass(activeClass)) {
      el.removeClass(activeClass).find('.faq--list-item-answer').slideUp(250);
    } else {
      el.siblings().removeClass(activeClass).find('.faq--list-item-answer').slideUp(250);
      el.addClass(activeClass).find('.faq--list-item-answer').slideDown(250);
    }
  })
}

export function listGradientPosition() {
  const element = $('.case-study-list__gradient');

  if (!element.length) return;

  const itemHeight = $('.case-studies--item').last().outerHeight();
  const gradientHeight = $('.case-study-list--gradient-bg').outerHeight();

  $('.case-study-list--wrapper').css('marginBottom', -(itemHeight - gradientHeight + 32));
}

export function scrollToElement() {
  const elements = $('[data-scroll-to]');

  if (!elements.length) return;

  elements.on('click', function (e) {
    e.preventDefault();

    const target = $(this).attr('data-scroll-to');
    const offset = $(this).attr('data-scroll-to-offset') ? parseInt($(this).attr('data-scroll-to-offset')) : 0;

    if (target == 'next-section') {
      $('html, body').scrollTop($(this).parents('section').first().next().offset().top - 75); // 75 = scrolled header height
    } else {
      $('html, body').scrollTop($(target).offset().top - offset - 75); // 75 = scrolled header height
    }
  })
}

export function loadMoreCaseStudy() {
  const target = $('.case-study-list--btn > button');

  if (!target.length) return;

  target.on('click', function () {
    const btn = $(this);
    const btnWrapper = btn.parent();
    const list = btnWrapper.prev('.case-study-list--wrapper');
    const currentPage = Number(list.attr('data-current-page'));
    const postsPerPage = Number(list.attr('data-posts-per-page'));
    const url = window.page.apiUrl + 'savangard/v1/cs?page=' + currentPage + '&ppp=' + postsPerPage;

    list.addClass('case-study-list--wrapper__loading');
    btn.attr('disabled', true);

    $.post(url)
      .done((response) => {
        if (response.list) {
          response.list.forEach((item) => {
            const newItem = `
              <div class="col-lg-6">
                <a href="${item.permalink}" class="case-studies--item">
                  ${item.clientLogo && '<div class="case-studies--item-logo"><img src="' + item.clientLogo + '" alt="' + item.title + '"></div>'}
                  <div class="case-studies--item-thumb">
                    ${item.thumbnail && '<img src="' + item.thumbnail + '" alt="' + item.title + '">'}
                  </div>
                  <div class="case-studies--item-data">
                    <h3 class="case-studies--item-title">${item.title}</h3>
                    <span class="case-studies--item-excerpt">${item.excerpt}</span>
                  </div>
                </a>
              </div>
            `;

            list.append(newItem);
          });
        }

        if (response.isEnd) {
          btnWrapper.hide();
          list.removeAttr('style').removeClass('case-study-list--wrapper__gradient').parents('.case-study-list').removeClass('case-study-list__gradient');
        } else {
          list.attr('data-current-page', currentPage + 1);
        }
      })
      .always(() => {
        list.removeClass('case-study-list--wrapper__loading');
        btn.attr('disabled', false);
      })
  })
}

export function loadMoreBlogPosts() {
  const target = $('.blog-list--btn > button');

  if (!target.length) return;

  target.on('click', function () {
    const btn = $(this);
    const btnWrapper = btn.parent();
    const list = btnWrapper.prev('.blog-list--wrapper');
    const currentPage = Number(list.attr('data-current-page'));
    const postsPerPage = Number(list.attr('data-posts-per-page'));
    const url = window.page.apiUrl + 'savangard/v1/posts?page=' + currentPage + '&ppp=' + postsPerPage;

    list.addClass('blog-list--wrapper__loading');
    btn.attr('disabled', true);

    $.post(url)
      .done((response) => {
        if (response.list) {
          response.list.forEach((item) => {
            let categories = '';

            item.categories.forEach(item => {
              categories += '<div class="blog-articles--item-category">' + item.name + '</div>';
            });

            const newItem = `
              <article class="blog-articles--item blog-articles--item__normal">
                <a href="${item.permalink}" class="blog-articles--item-wrapper">
                  <figure class="blog-articles--item-thumb">
                    ${item.thumbnail && '<img src="' + item.thumbnail + '" alt="' + item.title + '">'}
                  </figure>
                  <div class="blog-articles--item-data">
                    ${categories}

                    <h3 class="blog-articles--item-title">${item.title}</h3>
                    <p class="blog-articles--item-excerpt">${item.excerpt}</p>

                    <footer class="blog-articles--item-user">
                      <img src="${item.user.photo}" alt="${item.user.name}" class="blog-articles--item-user-photo">
                      <div class="blog-articles--item-user-content">
                        <div class="blog-articles--item-user-name">${item.user.name}</div>
                        <div class="blog-articles--item-user-bio">${item.user.bio}</div>
                      </div>
                    </footer>

                    <div class="blog-articles--item-read-time">${item.readTime}</div>
                  </div>
                </a>
              </article>`;

            list.append(newItem);
          });
        }

        if (response.isEnd) {
          btnWrapper.hide();
          list.removeAttr('style').removeClass('blog-list--wrapper__gradient').parents('.blog-list').removeClass('blog-list__gradient');
        } else {
          list.attr('data-current-page', currentPage + 1);
        }
      })
      .always(() => {
        list.removeClass('blog-list--wrapper__loading');
        btn.attr('disabled', false);
      })
  })
}

export function loadMorePodcasts() {
  const target = $('.podcast-list--btn > button');

  if (!target.length) return;

  target.on('click', function () {
    const btn = $(this);
    const btnWrapper = btn.parent();
    const list = btnWrapper.prev('.podcast-list--wrapper');
    const currentPage = Number(list.attr('data-current-page'));
    const postsPerPage = Number(list.attr('data-posts-per-page'));
    const url = window.page.apiUrl + 'savangard/v1/podcasts?page=' + currentPage + '&ppp=' + postsPerPage;

    list.addClass('podcast-list--wrapper__loading');
    btn.attr('disabled', true);

    $.post(url)
      .done((response) => {
        console.log(response);
        if (response.html) {
          list.append(response.html)
        }

        if (response.isEnd) {
          btnWrapper.hide();
          list.removeAttr('style').removeClass('podcast-list--wrapper__gradient').parents('.podcast-list').removeClass('podcast-list__gradient');
        } else {
          list.attr('data-current-page', currentPage + 1);
        }
      })
      .always(() => {
        list.removeClass('podcast-list--wrapper__loading');
        btn.attr('disabled', false);
      })
  })
}

let oldScroll = 0;

export function toggleScrolledClass() {
  if (!isMobile()) {
    const body = $('body');
    const header = $('.header');

    if (window.scrollY > 140) {
      body.addClass('scrolled');
    }

    if (oldScroll > window.scrollY) {
      if (window.scrollY < header.outerHeight()) {
        header.css('marginTop', -(window.scrollY - header.outerHeight() + 11));
      }
      body.addClass('scrolled-up');
    } else {
      body.removeClass('scrolled-up')
    }

    if (window.scrollY == 0) {
      body.removeClass('scrolled scrolled-up')
      header.removeAttr('style');
    }

    oldScroll = window.scrollY;
  }
}

export function toggleTeams() {
  const target = $('.teams--list-item');

  if (!target.length) return;

  target.on('click', function () {
    $(this)
      .addClass('teams--list-item__active')
      .siblings()
      .removeClass('teams--list-item__active');

    $(this)
      .parents('section')
      .find('.teams--desc')
      .eq($(this).index())
      .addClass('teams--desc__active')
      .siblings()
      .removeClass('teams--desc__active');
  });
}


export function addImagesGradient() {
  const target = $('.teams--desc');

  if (!target.length) return;

  target.find('img').each(function (index, item) {
    $(item).parent().addClass('gradient');
  })
}
