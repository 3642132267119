// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js';

// import local dependencies
import * as aos from './bundle/functions/aos';
import * as helpers from './bundle/functions/helpers';
import * as mainMenu from './bundle/functions/main-menu';
import * as stretchElements from './bundle/functions/stretch-elements';
import * as sliders from './bundle/functions/sliders';
import * as article from './bundle/functions/article';
import * as accordion from './bundle/functions/accordion';
import * as jobOffersList from './bundle/functions/job-offers-list';
import * as podcast from './bundle/functions/podcast';

// On window load
$(() => {
  aos.init();

  mainMenu.init();

  helpers.acceptCookies();
  helpers.commonSectionMoreToggle();
  helpers.setHeadingBgHeight();
  helpers.possibilitiesAccordion();
  helpers.faqAccordion();
  helpers.scrollToElement();
  helpers.loadMoreCaseStudy();
  helpers.loadMoreBlogPosts();
  helpers.loadMorePodcasts();
  helpers.listGradientPosition();
  helpers.toggleScrolledClass();
  helpers.toggleTeams();
  helpers.addImagesGradient();

  stretchElements.commonStretch();
  stretchElements.listItemStretch();

  sliders.commonSlider();
  sliders.podcastSlider();
  sliders.testimonials();
  sliders.timeline();

  article.init();
  article.initShareBtns();
  podcast.init();


  accordion.init();

  jobOffersList.init();

  setTimeout(() => {
    helpers.setHeadingBgHeight();
    helpers.listGradientPosition();
  }, 100);
});

// On window resize
$(window).resize(() => {
  helpers.setHeadingBgHeight();
  helpers.listGradientPosition();
});

// On window scroll
$(window).scroll(() => {
  helpers.toggleScrolledClass();
});
