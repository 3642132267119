import * as helpers from './helpers';

const MAX_WIDTH = 2000;

export function commonStretch() {
  const target = $('.stretch-common');
  const targetRelated = $('.stretch-common-related');

  if (!target.length) return;

  if (target.find('.stretch-common-disabled').length) {
    target.on('mousemove', function (e) {
      const isDisabled = $(e.target).hasClass('stretch-common-disabled') || $(e.target).parents('.stretch-common-disabled').length > 0;
      if (!helpers.isMobile()) {
        if (!isDisabled) {
          const newWidth = $(window).innerWidth() - $(this).offset().left;
          $(this).width(Math.min(MAX_WIDTH, newWidth));
        } else {
          $(this).width('calc(100% - 3.2rem)');
        }
      }
    });
  }

  target.hover(function (e) {
    const isDisabled = $(e.target).hasClass('stretch-common-disabled') || $(e.target).parents('.stretch-common-disabled').length > 0;
    if (!helpers.isMobile() && !isDisabled) {
      const newWidth = $(window).innerWidth() - $(this).offset().left;
      $(this).width(Math.min(MAX_WIDTH, newWidth));
    }
  }, function () {
    if (!helpers.isMobile()) {
      $(this).width('calc(100% - 3.2rem)');
    }
  })

  targetRelated.hover(function () {
    if (!helpers.isMobile()) {
      const relatedStretch = $(this).parents('section').first().find('.stretch-common');
      const newWidth = $(window).innerWidth() - relatedStretch.offset().left;
      relatedStretch.width(Math.min(MAX_WIDTH, newWidth));
    }
  }, function () {
    if (!helpers.isMobile()) {
      const relatedStretch = $(this).parents('section').first().find('.stretch-common');
      relatedStretch.width('calc(100% - 3.2rem)');
    }
  })
}

export function listItemStretch() {
  const target = $('.stretch-list-item > *');

  if (!target.length) return;

  target.hover(function () {
    if (!helpers.isMobile()) {
      const parentItem = $(this).parent().parent();
      const line = parentItem.find('hr');
      const newWidth = $(window).innerWidth() - parentItem.find('hr').offset().left;

      parentItem.addClass('hover');
      line.width(Math.min(MAX_WIDTH, newWidth));
    }
  }, function () {
    if (!helpers.isMobile()) {
      const parentItem = $(this).parent().parent();
      const line = parentItem.find('hr');

      parentItem.removeClass('hover');
      line.width('100%');
    }
  })
}
