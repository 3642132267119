// import { isMobile } from './helpers';

export function init() {
  const items = $('.job-offers-list--item');

  if (!items.length) return;

  const filters = $('.job-offers-list--filters-item');

  const filterRemotely = document.querySelector('[data-filter-location-remote]');
  let filterRemotelyText;
  if (filterRemotely) {
    filterRemotelyText = filterRemotely.getAttribute('data-filter-location');
  }
  if (!filterRemotelyText) filterRemotelyText = 'Zdalnie';

  let selectedLocations = [];
  let selectedPositions = [];

  const selectFilter = function () {
    const el = $(this);
    const isLocation = el.is('[data-filter-location]');
    const value = isLocation ? el.attr('data-filter-location') : el.attr('data-filter-position');
    const valueIndex = isLocation ? selectedLocations.indexOf(value) : selectedPositions.indexOf(value);
    const activeClass = 'job-offers-list--filters-item__active';

    if (valueIndex !== -1) {
      el.removeClass(activeClass);
      isLocation ? selectedLocations.splice(valueIndex, 1) : selectedPositions.splice(valueIndex, 1);
    } else {
      el.addClass(activeClass);
      isLocation ? selectedLocations.push(value) : selectedPositions.push(value);
    }


    if (!selectedLocations.length && !selectedPositions.length) {
      showAllItems();
    } else {
      filterItems();
    }
  };

  const filterItems = function () {
    items.each((index, item) => {
      const el = $(item);
      const elPostions = [];
      const title = el.find('.job-offers-list--item-title').text();

      el.find('.job-offers-list--item-position').each((i, e) => {
        elPostions.push($(e).text());
      })

      let hasPosition = selectedPositions.length ? false : true;
      let hasLocation = selectedLocations.length ? false : true;

      selectedPositions.forEach(position => {
        if (elPostions.indexOf(position) !== -1) hasPosition = true;
      })

      selectedLocations.forEach(location => {
        // if (filterRemotely.classList.contains('job-offers-list--filters-item__active')) {
        //   if (el.find('.job-offers-list--item-location').text().indexOf(location) !== -1 &&
        //     el.find('.job-offers-list--item-location').text().indexOf(filterRemotelyText) !== -1) {
        //     hasLocation = true;
        //   }
        // } else {
          if (el.find('.job-offers-list--item-location').text().indexOf(location) !== -1) hasLocation = true;
        // }
      })

      if (!hasLocation || !hasPosition) {
        el.parent().addClass('d-none');
      } else {
        el.parent().removeClass('d-none');
      }

      emptyMsgHandler();
    });
  };

  const showAllItems = function () {
    items.each((index, item) => {
      $(item).parent().removeClass('d-none');
    });

    emptyMsgHandler();
  };

  const emptyMsgHandler = function () {
    const msgEl = $('.job-offers-list--empty');
    let showMsg = true;

    items.each((index, item) => {
      if (!$(item).parent().hasClass('d-none')) showMsg = false;
    });

    showMsg ? msgEl.removeClass('d-none') : msgEl.addClass('d-none');
  };

  filters.on('click', selectFilter);
}
