export function init() {
  const section = document.querySelector('.podcast-content');
  const content = document.querySelector('.podcast-content--content-container');
  const list = document.querySelector('.podcast-content--table-of-contents-list');

  if (!section || !content || !list) {
    return;
  }

  const headings = content.querySelectorAll('h2[id], h3[id], h4[id], h5[id], h6[id]');

  if (headings.length === 0) {
    return;
  }

  headings.forEach(heading => {
    const listItem = document.createElement('li');
    const anchor = document.createElement('a');
    anchor.href = `#${heading.id}`;
    anchor.textContent = heading.textContent;
    listItem.appendChild(anchor);
    list.appendChild(listItem);
  });

}
