import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { isMobile } from './helpers';

export function init() {
  const header = $('.header');
  const headerMenuBg = header.find('.header--menu-hover-bg');
  const headerMenuBack = header.find('.header--menu-back');
  const headerMenuClose = header.find('.header--menu-close');
  const headerOpenMenuBtn = header.find('.header--toggle-menu svg');
  const submenus = header.find('.menu-item-has-children');

  const menu = document.querySelector('.header--menu');

  const openMobileMenu = () => {
    header.addClass('header__menu-open');
    disableBodyScroll(menu);
  }

  const closeMobileMenu = () => {
    header.removeClass('header__menu-open');
    closeMobileSubmenu();
    enableBodyScroll(menu);
  }

  const enterHoverMenu = (menuItem) => {
    if (!isMobile()) {
      const submenu = $(menuItem).children('ul');

      $(menuItem).addClass('hover');
      header.addClass('header__menu-hover');
      headerMenuBg
        .height(submenu.outerHeight() + submenu.offset().top - window.scrollY)
        .css('opacity', 1);
    }
  }

  const leaveHoverMenu = (menuItem) => {
    $(menuItem).removeClass('hover');
    header.removeClass('header__menu-hover');
    headerMenuBg.css('opacity', 0);
  }

  const openMobileSubmenu = (e, menuItem) => {
    if (!isMobile()) return;

    e.preventDefault();

    const submenu = $(menuItem).next('ul');

    headerMenuBack.show();
    submenu.prepend($(menuItem).clone().addClass('submenu--heading'));

    $(menuItem).css('opacity', 0);
    $(menuItem).parent().siblings().css('opacity', 0);
    submenu.addClass('active');
  }

  const closeMobileSubmenu = () => {
    header.find('ul.active').prev('a').css('opacity', 1);
    header.find('ul.active').parent().siblings().css('opacity', 1);

    header.find('ul.active').children('a').remove();
    header.find('ul.active').removeClass('active');
    headerMenuBack.hide();
  }

  headerOpenMenuBtn.on('click', openMobileMenu);
  headerMenuClose.on('click', closeMobileMenu);
  headerMenuBack.on('click', closeMobileSubmenu);
  submenus.children('a').on('click', function (e) { openMobileSubmenu(e, this); });
  submenus.hover(function () { enterHoverMenu(this) }, function () { leaveHoverMenu(this) });
}
