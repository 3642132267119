import Swiper from 'swiper/bundle';

export function commonSlider() {

  const sliderSections = document.querySelectorAll('.slider');

  if (!sliderSections) return;

  sliderSections.forEach(section => {
    const sliderEl = section.querySelector('.slider--slider');

    if (!sliderEl) return;

    new Swiper(sliderEl, {
      slidesPerView: 1,
      speed: 500,
      slideToClickedSlide: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      breakpoints: {
        450: {
          slidesPerView: 2,
          spaceBetween: 6,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 8,
        },
      },
    });

  });

}

export function podcastSlider() {

  const sliderSections = document.querySelectorAll('.podcast-slider');

  if (!sliderSections) return;

  sliderSections.forEach(section => {
    const sliderEl = section.querySelector('.podcast-slider--slider');

    if (!sliderEl) return;

    new Swiper(sliderEl, {
      slidesPerView: 1,
      speed: 500,
      spaceBetween: 16,
      slideToClickedSlide: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      navigation: {
        nextEl: Array.from(section.querySelectorAll('.podcast-slider--nav-next')),
        prevEl: Array.from(section.querySelectorAll('.podcast-slider--nav-prev')),
      },
      breakpoints: {
        650: {
          slidesPerView: 2,
          spaceBetween: 24,
          centeredSlides: false,
          centeredSlidesBounds: false,
        },
        1025: {
          slidesPerView: 2.5,
          spaceBetween: 32,
          centeredSlides: false,
          centeredSlidesBounds: false,
        },
      },
    });

  });

}

export function testimonials() {

  const testimonialsSections = document.querySelectorAll('.testimonials');

  if (!testimonialsSections) return;

  testimonialsSections.forEach(section => {
    const sliderEl = section.querySelector('.testimonials--slider');
    if (!sliderEl) return;
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    if (slides.length >= 2) {

      new Swiper(sliderEl, {
        speed: 500,
        slideToClickedSlide: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        loop: true,
        loopedSlides: 3,
        allowTouchMove: false,
        effect: 'creative',
        pagination: {
          el: section.querySelector('.testimonials--pagination'),
          clickable: true,
        },
        creativeEffect: {
          limitProgress: 3,
          progressMultiplier: 2,
          prev: {
            translate: ['-1rem', '3rem', 0],
            opacity: 0,
          },
          next: {
            translate: ['2rem', '-6rem', 0],
            opacity: .7,
          },
        },
        breakpoints: {
          1025: {
            creativeEffect: {
              limitProgress: 3,
              progressMultiplier: 2,
              prev: {
                translate: ['-3rem', '3rem', 0],
                opacity: 0,
              },
              next: {
                translate: ['6rem', '-6rem', 0],
                opacity: .7,
              },
            },
          },
        },
      });

    }

  });

}


export function timeline() {

  const timelineSections = document.querySelectorAll('.timeline, .timeline-alt, .evolution');

  if (!timelineSections) return;

  timelineSections.forEach(section => {
    const sliderEl = section.querySelector('.timeline-slider');

    if (!sliderEl) return;

    new Swiper(sliderEl, {
      slidesPerView: 1,
      speed: 500,
      slideToClickedSlide: true,
    });

  });

}

