import AOS from 'aos';

export function init() {
  if (
    !document.querySelector('html').classList.contains('md-mobile') && !document.querySelector('html').classList.contains('ie')
    && (
      document.querySelector('[aos]') !== null
      || document.querySelector('[data-aos]') !== null
    )
  ) {
    setTimeout(function () {
      AOS.init({
        disable: 'mobile',
        duration: 500,
        easing: 'ease-in-out',
        offset: 50,
        triggerEvent: true,
        once: true,
      });
    }, 500);
  }

  $(window).on('scroll', function () {
    AOS.refresh();
  });

  $(window).on('resize', function () {
    AOS.refresh();
  });

  initPodcastHeroAnim();

}

function initPodcastHeroAnim () {

  const container = document.querySelector('.podcast-list--animation');

  let scrollPercentage;

  const update = () => {
    const containerRect = container.getBoundingClientRect();
    const containerOffset = wsGetOffset(container);
    const bottom = containerOffset.top + containerRect.height;
    window.scrollY / bottom > 1 ? scrollPercentage = 1.0 : scrollPercentage = window.scrollY / bottom;
    container.style.setProperty('--scroll-percentage', scrollPercentage);
  }

  if (container) {
    update();
    window.addEventListener('scroll', update);
    window.addEventListener('resize', update);
  }


}


function wsGetOffset (elem) {
  try {
    const box = elem.getBoundingClientRect();
    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.scrollY || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.scrollX || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return {top: Math.round(top), left: Math.round(left)};
  } catch (e) {
    return {top: null, left: null};
  }
}
