
import { isMobile } from './helpers';

export function init() {


  const blogList = document.querySelector('.blog-list .blog-articles--list');

  if (blogList) {

    const updateGrid = () => {

      const articles = blogList.querySelectorAll(':scope > .blog-articles--item');

      let featuredArticles = null;

      if (window.innerWidth >= 1300) {
        featuredArticles = blogList.querySelectorAll(':scope > .blog-articles--item:nth-child(5)');
      } else if (window.innerWidth >= 1025) {
        featuredArticles = blogList.querySelectorAll(':scope > .blog-articles--item:nth-child(4)');
      }
      if (window.innerWidth >= 576) {
        featuredArticles = blogList.querySelectorAll(':scope > .blog-articles--item:nth-child(3)');
      }

      if (articles) {
        articles.forEach(article => {
          article.classList.replace('blog-articles--item__featured', 'blog-articles--item__normal')
        });
      }

      if (featuredArticles) {
        featuredArticles.forEach(article => {
          article.classList.replace('blog-articles--item__normal', 'blog-articles--item__featured')
        });
      }

    }

    const mutationObserver = new MutationObserver(updateGrid);
    const resizeObserver = new ResizeObserver(updateGrid);

    mutationObserver.observe(blogList, { childList: true });
    resizeObserver.observe(blogList);

  }
}

export function initShareBtns () {


  const shareBtns = document.querySelectorAll('[data-article-share]');


   const unsecuredCopyToClipboard = (element, text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.classList.add('sr-only');
    element.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    element.removeChild(textArea);
  };

   const copyToClipboard = (element, content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      unsecuredCopyToClipboard(element, content);
    }
    element.classList.add('show-tooltip');
    setTimeout(() => {
      element.classList.remove('show-tooltip');
    }, 4000);
  };

  if (shareBtns) {
    shareBtns.forEach(shareBtn => {
      const data = JSON.parse(shareBtn.getAttribute('data-article-share'));
      if (data) {
        shareBtn.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          copyToClipboard(shareBtn, data.url);
        });
      }

    });
  }

}
